'use strict'
import configs from '@/plugins/configs.js'
const dicamo = configs.LcdUrl

// console.log(configs.LcdUrl)
const methods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete'
}

const dicamoApi = {
  template: dicamo + '/assets/SolumMedia/Label/',
  media: dicamo + '/assets/SolumMedia/',
  requests: {
    // Dicamo Overview
    getOverviewData: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumOverviewA'
    },
    // Dicamo Media
    getMedia: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumMediaA/get'
    },
    getMediaItem: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumMediaA/get'
    },
    addMedia: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumMediaA/post'
    },
    editMediaItem: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumMediaA/put'
    },
    deleteMediaItem: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumMediaA/delete'
    },
    getCategory: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumMediaA/get'
    },
    // Dicamo Playlist
    getPlaylist: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumPlaylistSetA/get'
    },
    postPlaylist: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumPlaylistSetA/post'
    },
    putPlaylist: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumPlaylistSetA/put'
    },
    deletePlaylist: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumPlaylistSetA/delete'
    },
    // DicamoLCDList
    getLcdList: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumLCDA/get/list'
    },
    getLcdTemplateAndProduct: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumTemplateA/get'
    },
    postLcdList: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumLCDA/post'
    },
    putLcdList: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumLCDA/put'
    },
    deleteLcdList: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumLCDA/delete'
    },
    getScheduleLMappingist: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumLCDA/get'
    },
    postScheduleMappingList: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumLCDA/put'
    },
    postServerSetting: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumLCDA/server'
    },
    getServerSetting: {
      method: methods.GET,
      url: dicamo + '/index.php/SolumLCDA/getServer'
    },

    // test: {
    //   method: methods.POST,
    //   url: dicamo + '/SolumLCDA/test'
    // },

    // DICAMO Template

    getTemplateList: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumTemplateA/get'
    },
    deleteTemplateList: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumTemplateA/delete'
    },
    postTemplateList: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumTemplateA/post'
    },
    putTemplateList: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumTemplateA/put'
    },
    getAssignTemplateList: {
      method: methods.GET,
      url: dicamo + '/index.php/SolumA/Template'
    },
    setTemplatePreview: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumTemplateA/put'
    },
    getTemplateExport: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumTemplateA/get'
    },
    setTemplateImport: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumTemplateA/put'
    },
    setSleepSchedule: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumLCDA/put'
    },
    getDefaultGroup: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumMediaA/get'
    },
    assignLcd: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumA/Stripe/Post'
    },
    commandLcd: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumA/Stripe/Cmd'
    },
    thumbnail: {
      url: dicamo + '/assets/thumbnail/'
    },
    labelThumbnail: {
      url: dicamo + '/assets/SolumMedia/Label/'
    },

    getTemplateMapping: {
      method: methods.GET,
      url: dicamo + '/index.php/SolumTemplateMapping/list'
    },
    setTemplateMapping: {
      method: methods.POST,
      url: dicamo + '/index.php/SolumTemplateMapping/set'
    }
  },
  EXPORT_SIZE: 1000000000
}

export default dicamoApi
