var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", scrollable: "", persistent: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn",
                      attrs: {
                        disabled: _vm.btnDisabledNewEditDelete,
                        text: ""
                      },
                      on: { click: _vm.newMapping }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("Add")))]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.mappingDialog,
        callback: function($$v) {
          _vm.mappingDialog = $$v
        },
        expression: "mappingDialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-template" }),
              _vm._v(_vm._s(_vm.$t(_vm.type + " Mapping")))
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        ref: "tartgetField",
                        staticClass: "form-select",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: "" + _vm.$t("Target Field"),
                          placeholder: "" + _vm.$t("Select the target field"),
                          items: _vm.targetFieldItems,
                          clearable: ""
                        },
                        model: {
                          value: _vm.targetField,
                          callback: function($$v) {
                            _vm.targetField = $$v
                          },
                          expression: "targetField"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        ref: "expectedValue",
                        staticClass: "form-input",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: "" + _vm.$t("Expected Value"),
                          placeholder: "" + _vm.$t("Input the expected value"),
                          clearable: ""
                        },
                        model: {
                          value: _vm.expectedValue,
                          callback: function($$v) {
                            _vm.expectedValue = $$v
                          },
                          expression: "expectedValue"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        ref: "linkedTemplateGroup",
                        staticClass: "form-select",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: "" + _vm.$t("Template Item"),
                          placeholder: "" + _vm.$t("Template Item"),
                          items: _vm.templateList,
                          "item-text": "template_name",
                          "item-value": "idx_template",
                          clearable: ""
                        },
                        model: {
                          value: _vm.templateIndex,
                          callback: function($$v) {
                            _vm.templateIndex = $$v
                          },
                          expression: "templateIndex"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.setMapping()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Save")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.closeMapping }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }