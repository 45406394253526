<template>
  <div>
    <h2 class="page-title-bar">
      <i class="ico ico-template"></i>{{ $t("Template Mapping") }}
    </h2>
    <section class="searchArea justify-space-between">
    </section>
    <v-data-table
      v-model="selected"
      :headers="tableHeaders"
      :items="templateMappingList"
      :page.sync="page"
      :hide-default-footer="true"
      :items-per-page="-1"
      item-key="key"
      show-select
      fixed-header
      height="528"
      class="tbl-type01 mt-10"
    >
      <template slot="no-data">
        <p>
          {{ $t('No data available') }}
        </p>
      </template>
      <!-- <template v-slot:body="{ items, isSelected, select }">
        <tbody>
          <tr v-for="template in items" :key="template.idx_template">
            <td> <v-checkbox :input-value="isSelected(template)" style="margin: 0px; padding: 0px;" hide-details @click="select(template, !isSelected(template))" /> </td>
            <td> {{ template.priority }} </td>
            <td> {{ template.target_field }} </td>
            <td> {{ template.expected_value }} </td>
            <td> {{ template.title }} </td>
          </tr>
        </tbody>
      </template> -->
    </v-data-table>
    <div class="innerButton">
      <div class="mt-2 mb-2">
        <v-btn text icon @click="moveRow('top')"><img src="@/assets/img/ico-move-top.png" alt=""></v-btn>
        <v-btn text icon @click="moveRow('up')"><img src="@/assets/img/ico-move-up.png" alt=""></v-btn>
        <v-btn text icon @click="moveRow('down')"><img src="@/assets/img/ico-move-down.png" alt=""></v-btn>
        <v-btn text icon @click="moveRow('bottom')"><img src="@/assets/img/ico-move-bottom.png" alt=""></v-btn>
      </div>
    </div>
    <div class="btn-wrap">
      <v-row>
        <v-col cols="12">
          <mapping-dialog @addMapping="addItem" @editMapping="editItem" :isChange="isChange"/>
          <v-btn class="btn ml-3" text :disabled="selected.length !== 1" @click="editMapping"> {{ $t('Edit') }} </v-btn>
          <v-btn class="btn ml-3" text :disabled="selected.length === 0" @click="deleteDialog = true"> {{ $t('Delete') }} </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="mb-2">
            <!-- <v-btn @click="newTemplate()" text class="btn" :disabled="addBtnDisabled">{{ $t("New") }} </v-btn> -->
            <v-btn class="btn" text @click="saveMapping"> {{ $t('Save') }} </v-btn>
            <!-- <v-btn @click="editTemplate()" text class="btn ml-3" :disabled="editBtnDisabled">{{ $t("Save") }} </v-btn>
            <v-btn text class="btn ml-3" :disabled="deleteBtnDisabled" @click="deleteDialog = true">{{ $t("Delete") }} </v-btn> -->
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="deleteDialog" width="370">
      <v-card class="popup-success">
        <i class="ico-error"> <img src="@/assets/img/ico-unassign.png" alt=""> </i>
        <div class="msg">
            <h3>{{ $t('Delete') }}</h3>
            <p>{{ $t('Are you sure you want to delete?') }}</p>
        </div>
        <v-card-actions class="d-flex justify-center btnArea">
          <v-btn text icon class="btn" @click="listDelete">{{$t('Yes')}}</v-btn>
          <v-btn text icon @click="deleteDialog = false" class="btn">{{$t('No')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dicamoApi from '@/lcd/plugins/dicamoApi'
import EventBus from '@/plugins/eventBus'
// import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { mapGetters } from 'vuex'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import MappingDialog from '@/lcd/views/Template/modal/MappingDialog'
import Axios from 'axios'
/**
 * Company
 * Store
 * Data -
 *        priority
 *        target_field
 *        expected_value
 *        idx_template
 *        company
 *        store
 *
 */
export default {
  name: 'DicamoTemplate',
  components: {
    MappingDialog
    // PerfectScrollbar
  },
  data () {
    return {
      search: {
        templateTitle: '',
        templateId: ''
      },
      isChange: false,
      isLoading: false,
      deleteDialog: false,
      selected: [],
      templateMappingList: [],
      viewTemplateList: true,
      btnDisabledNewEditDelete: true,
      btnDisabledDetail: true,
      page: 1
    }
  },
  created () {
    this.user = this.$store.state.auth.user
  },
  beforeRouteLeave (to, from, next) {
    if (!this.isChange) {
      next()
    } else {
      this.$confirm(this.$t('Are you sure you want to leave this page? \n Changes may not be saved.')).then(() => {
        next()
      })
    }
  },
  mounted () {
    // LCD PlaylistGroup Mapping / Assigng
    this.$store.dispatch('auth/getDisabledBtn', '25100').then(flag => {
      this.viewTemplateList = flag
    })
    // LCD Registration
    this.$store.dispatch('auth/getDisabledBtn', '25001').then(flag => {
      this.btnDisabledNewEditDelete = flag
    })
    // LCD Edit / Delete
    this.$store.dispatch('auth/getDisabledBtn', '25002').then(flag => {
      this.btnDisabledDetail = flag
    })
    EventBus.$emit('enableSelectedStores', true)
    if (!this.store.code) {
      EventBus.$emit('openSelectedStores')
    } else {
      this.getTemplateMappingList()
    }
  },
  methods: {
    addItem (item) {
      const addItem = item()
      this.templateMappingList.push(addItem)
      this.isChange = true
      this.tableSort()
    },
    editItem (item) {
      const data = item()
      const index = data.index
      delete data.index
      data.key = this.getMappingKey()
      this.templateMappingList.splice(index, 1, data)
      this.tableSort()
      this.isChange = true
      this.selected = []
    },
    editMapping () {
      const item = this.selected[0]
      const index = this.templateMappingList.findIndex(r => {
        if (r === item) return true
      })
      EventBus.$emit('editMapping', item, index)
    },
    saveMapping () {
      // if (this.templateMappingList.length < 1) {
      //   // EventBus.$emit('messageAlert', '')
      //   return
      // }
      const mappingDataFormat = []
      this.templateMappingList.forEach(item => {
        mappingDataFormat.push({
          priority: item.priority,
          target_field: item.target_field,
          expected_value: item.expected_value,
          idx_template: item.idx_template,
          company: this.$store.state.auth.user.company,
          store: this.$store.state.dataStore.selectedStore.code
        })
      })

      const data = {
        company: this.$store.state.auth.user.company,
        store: this.$store.state.dataStore.selectedStore.code,
        data: mappingDataFormat
      }

      // data.append('company', this.$store.state.auth.user.company)
      // data.append('store', this.$store.state.dataStore.selectedStore.code)
      // data.append('data', JSON.stringify(data))

      Axios.post(dicamoApi.requests.setTemplateMapping.url, data, { headers: { 'Content-Type': 'text/plain' } })
        .then(res => {
          EventBus.$emit('messageAlert', this.$t('SUCCESS'))
          // console.log(res.data)
        })
        .catch(error => {
          // console.log(error)
          console.debug(`error: ${error}`)
        })

      // this.$utils
      //   .callAxiosWithBody(
      //     dicamoApi.requests.setTemplateMapping.method,
      //     dicamoApi.requests.setTemplateMapping.url,
      //     data
      //   )
      //   .then(res => {
      //     console.log(res.data)
      //   })
      //   .catch(error => {
      //     console.log(error)
      //   })

      this.isChange = false
    },

    moveRow (type) {
      const list = this.selected
      this.isLoading = true
      // const table = this.templateMappingList.slice()
      // console.log('시작')
      this.isChange = true

      if (type === 'top') {
        list.sort((a, b) => {
          if (a.priority > b.priority) return 1
          else if (a.priority < b.priority) return -1
          return 0
        })
        for (const item of list) {
          const index = this.templateMappingList.findIndex(r => r.key === item.key)
          if (index > -1) {
            this.templateMappingList.splice(index, 1)
            this.templateMappingList.unshift(item)
          }
        }
      } else if (type === 'up') {
        list.sort((a, b) => {
          if (a.priority > b.priority) return 1
          else if (a.priority < b.priority) return -1
          return 0
        })
        for (const item of list) {
          const index = this.templateMappingList.findIndex(r => r.key === item.key)
          if (index > -1 && index !== 0) {
            this.templateMappingList.splice(index, 1)
            this.templateMappingList.splice(index - 1, 0, item)
          }
        }
      } else if (type === 'down') {
        list.sort((a, b) => {
          if (a.priority > b.priority) return -1
          else if (a.priority < b.priority) return 1
          return 0
        })
        for (const item of list) {
          const index = this.templateMappingList.findIndex(r => r.key === item.key)
          if (index > -1) {
            this.templateMappingList.splice(index, 1)
            this.templateMappingList.splice(index + 1, 0, item)
          }
        }
      } else if (type === 'bottom') {
        list.sort((a, b) => {
          if (a.priority > b.priority) return -1
          else if (a.priority < b.priority) return 1
          return 0
        })
        for (const item of list) {
          const index = this.templateMappingList.findIndex(r => r.key === item.key)
          if (index > -1) {
            this.templateMappingList.splice(index, 1)
            this.templateMappingList.push(item)
          }
        }
      }
      // this.selected = []
      // this.templateMappingList = table
      this.tableSort(this.selected)
      this.isLoading = false
      // console.log('종료')
    },
    listDelete () {
      this.selected.forEach(item => {
        const index = this.templateMappingList.findIndex(r => r.key === item.key)
        this.templateMappingList.splice(index, 1)
      })
      this.isChange = true
      this.selected = []
      this.tableSort()
      EventBus.$emit('messageAlert', this.$t('SUCCESS'))
      this.deleteDialog = false
    },
    tableSort () {
      // const list = []
      this.templateMappingList.forEach((item, index) => {
        item.priority = index + 1
      })
    },
    resetTemplate () {
      // document.getElementsByClassName('ps--active-y')[0].scrollTop = 0
      this.getTemplateMappingList()
      // this.page = 1
      this.selected = []
    },
    resetTemplateList () {
      this.templateMappingList = []
      this.selected = []
      this.getTemplateMappingList()
    },
    getMappingKey () {
      const date = new Date()
      return String(date.getTime()) + Math.random()
    },
    getTemplateMappingList () {
      const data = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.$store.state.dataStore.selectedStore.code
        }
      }
      Axios.get(dicamoApi.requests.getTemplateMapping.url, data)
        .then(res => {
          const list = res.data.data
          const tableList = []
          list.forEach(item => {
            item.key = this.getMappingKey()
            tableList.push(item)
          })
          this.templateMappingList = tableList
        })
        .catch(error => {
          console.log(error)
          console.debug(`error: ${error}`)
        })
      // this.$utils
      //   .callAxios(
      //     dicamoApi.requests.getTemplateMapping.method,
      //     dicamoApi.requests.getTemplateMapping.url,
      //     data
      //   )
      //   .then(res => {
      //     this.templateMappingList = res.data.data
      //   })
      //   .catch(error => {
      //     console.log(error)
      //     console.debug(`error: ${error}`)
      //   })
    }
  },
  computed: {
    tableHeaders () {
      return [
        { text: this.$t('PRIORITY'), value: 'priority', sortable: false, width: '25%' },
        { text: this.$t('TARGET FIELD'), value: 'target_field', sortable: false, width: '25%' },
        { text: this.$t('EXPECTED VALUE'), value: 'expected_value', sortable: false, width: '25%' },
        { text: this.$t('LINKED TEMPLATE'), value: 'title', sortable: false, width: '25%' }
      ]
    },
    ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' }),
    addBtnDisabled () {
      return this.btnDisabledNewEditDelete || !this.store.code
    },
    editBtnDisabled () {
      return this.btnDisabledNewEditDelete || this.selected.length !== 1
    },
    deleteBtnDisabled () {
      return this.btnDisabledNewEditDelete || this.selected.length < 1
    }
  },
  watch: {
    store: {
      handler () {
        this.selected = []
        this.getTemplateMappingList()
      }
    },
    page: {
      handler () {
        this.selected = []
        this.getTemplateMappingList()
      }
    }
  }
}
</script>

<style scoped>
::v-deep .v-input input:focus::placeholder {
  color: #fff;
}
.v-btn.type-search .v-btn__content {
  font-size: var(--font22);
  font-weight: 700;
}
</style>
