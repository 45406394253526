var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-template" }),
        _vm._v(_vm._s(_vm.$t("Template Mapping")) + " ")
      ]),
      _c("section", { staticClass: "searchArea justify-space-between" }),
      _c(
        "v-data-table",
        {
          staticClass: "tbl-type01 mt-10",
          attrs: {
            headers: _vm.tableHeaders,
            items: _vm.templateMappingList,
            page: _vm.page,
            "hide-default-footer": true,
            "items-per-page": -1,
            "item-key": "key",
            "show-select": "",
            "fixed-header": "",
            height: "528"
          },
          on: {
            "update:page": function($event) {
              _vm.page = $event
            }
          },
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _c("template", { slot: "no-data" }, [
            _c("p", [_vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")])
          ])
        ],
        2
      ),
      _c("div", { staticClass: "innerButton" }, [
        _c(
          "div",
          { staticClass: "mt-2 mb-2" },
          [
            _c(
              "v-btn",
              {
                attrs: { text: "", icon: "" },
                on: {
                  click: function($event) {
                    return _vm.moveRow("top")
                  }
                }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/ico-move-top.png"),
                    alt: ""
                  }
                })
              ]
            ),
            _c(
              "v-btn",
              {
                attrs: { text: "", icon: "" },
                on: {
                  click: function($event) {
                    return _vm.moveRow("up")
                  }
                }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/ico-move-up.png"),
                    alt: ""
                  }
                })
              ]
            ),
            _c(
              "v-btn",
              {
                attrs: { text: "", icon: "" },
                on: {
                  click: function($event) {
                    return _vm.moveRow("down")
                  }
                }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/ico-move-down.png"),
                    alt: ""
                  }
                })
              ]
            ),
            _c(
              "v-btn",
              {
                attrs: { text: "", icon: "" },
                on: {
                  click: function($event) {
                    return _vm.moveRow("bottom")
                  }
                }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/ico-move-bottom.png"),
                    alt: ""
                  }
                })
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "btn-wrap" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("mapping-dialog", {
                    attrs: { isChange: _vm.isChange },
                    on: { addMapping: _vm.addItem, editMapping: _vm.editItem }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn ml-3",
                      attrs: { text: "", disabled: _vm.selected.length !== 1 },
                      on: { click: _vm.editMapping }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Edit")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn ml-3",
                      attrs: { text: "", disabled: _vm.selected.length === 0 },
                      on: {
                        click: function($event) {
                          _vm.deleteDialog = true
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "mb-2" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn",
                        attrs: { text: "" },
                        on: { click: _vm.saveMapping }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "370" },
          model: {
            value: _vm.deleteDialog,
            callback: function($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup-success" },
            [
              _c("i", { staticClass: "ico-error" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/ico-unassign.png"),
                    alt: ""
                  }
                })
              ]),
              _c("div", { staticClass: "msg" }, [
                _c("h3", [_vm._v(_vm._s(_vm.$t("Delete")))]),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("Are you sure you want to delete?")))
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center btnArea" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.listDelete }
                    },
                    [_vm._v(_vm._s(_vm.$t("Yes")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("No")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }