<template>
  <v-dialog v-model="mappingDialog" width="500" scrollable persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="btn"
        v-bind="attrs"
        v-on="on"
        @click="newMapping"
        :disabled="btnDisabledNewEditDelete"
        text
      >{{ $t('Add') }}</v-btn>
    </template>
    <v-card class="popup add_store_popup">
      <v-card-title> <h3 class="page-title-bar"><i class="ico ico-template"></i>{{$t(`${type} Mapping`)}}</h3></v-card-title>
      <!--
      <v-row>
        <v-col>
          <v-text-field outlined dense hide-details
            label="Mapping Title"
            placeholder="Input the mapping title"
            v-model="SaveCondition.tartgetField"
            class="form-input"
          ></v-text-field>
        </v-col>
      </v-row>
      -->
      <v-card-text >
      <v-row>
        <v-col>
          <v-select
            outlined
            dense
            hide-details
            :label="`${$t('Target Field')}`"
            :placeholder="`${$t('Select the target field')}`"
            v-model="targetField"
            ref="tartgetField"
            :items="targetFieldItems"
            class="form-select"
            clearable
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field outlined dense hide-details
            :label="`${$t('Expected Value')}`"
            :placeholder="`${$t('Input the expected value')}`"
            v-model="expectedValue"
            ref="expectedValue"
            class="form-input"
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!--
          <v-text-field outlined dense hide-details
            label="Template Group"
            placeholder="Input template group"
            v-model="SaveCondition.linkedTemplateGroup"
            class="form-input ico-search",
          >-->
          <v-select
            outlined
            dense
            hide-details
            :label="`${$t('Template Item')}`"
            :placeholder="`${$t('Template Item')}`"
            v-model="templateIndex"
            :items="templateList"
            item-text="template_name"
            item-value="idx_template"
            ref="linkedTemplateGroup"
            class="form-select"
            clearable
          ></v-select>
          <!--
          <template v-slot:append>
            <img width="24" height="24" src="@/assets/img/ico-upload-file.gif" >
          </template>
          </v-text-field>
          -->

        </v-col>
      </v-row>
</v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn text icon  @click="setMapping()" class="btn">{{$t('Save')}}</v-btn>
        <v-btn text icon @click="closeMapping" class="btn">{{$t('Cancel')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
import dicamoApi from '@/lcd/plugins/dicamoApi'
import EventBus from '@/plugins/eventBus'
export default {
  props: {
    isChange: Boolean
  },
  data () {
    return {
      mappingDialog: false,
      targetField: '',
      expectedValue: '',
      templateIndex: '',
      targetFieldItems: [],
      templateList: [],
      type: '',
      index: '',
      btnDisabledNewEditDelete: true
    }
  },
  mounted () {
    this.getProductList()
    this.getTemplateList()
    EventBus.$on('editMapping', (item, index) => {
      this.mappingDialog = true
      this.type = 'Edit'
      this.expectedValue = item.expected_value
      this.targetField = item.target_field
      this.templateIndex = item.idx_template
      this.index = index
    })
    // LCD Registration
    this.$store.dispatch('auth/getDisabledBtn', '25001').then(flag => {
      this.btnDisabledNewEditDelete = flag
    })
  },
  methods: {
    newMapping () {
      this.type = 'New'
    },
    setMapping () {
      if (this.targetField === '' || this.expectedValue === '' || this.templateIndex === '') {
        EventBus.$emit('messageAlert', this.$t('Please enter the Data.'), 'error')
        return
      }
      if (this.type === 'New') {
        this.$emit('addMapping', () => {
          const title = this.templateList.find(r => {
            if (r.idx_template === this.templateIndex) return r
          })
          return {
            target_field: this.targetField,
            expected_value: this.expectedValue,
            idx_template: this.templateIndex,
            title: title.template_name,
            key: this.getMappingKey()
          }
        })
      } else if (this.type === 'Edit') {
        this.$emit('editMapping', () => {
          const title = this.templateList.find(r => {
            if (r.idx_template === this.templateIndex) return r
          })
          return {
            target_field: this.targetField,
            expected_value: this.expectedValue,
            idx_template: this.templateIndex,
            title: title.template_name,
            index: this.index,
            key: this.getMappingKey()
          }
        })
      }
      EventBus.$emit('messageAlert', this.$t('SUCCESS'))
      this.initialize()
      this.mappingDialog = false
    },
    getMappingKey () {
      const date = new Date()
      return String(date.getTime()) + Math.random()
    },
    initialize () {
      this.targetField = ''
      this.expectedValue = ''
      this.templateIndex = ''
      this.type = ''
      this.index = ''
    },
    closeMapping () {
      this.initialize()
      this.mappingDialog = false
    },

    getTemplateList () {
      // const data = new FormData()
      // this.isLoading = true

      // data.append('company', this.$store.state.auth.user.company)
      // data.append('store', this.$store.state.dataStore.selectedStore.code)
      // data.append('page', 1)
      // data.append('limit', 100000)
      // data.append('type', 'simplelist')

      // this.$utils
      //   .callAxiosWithBody(
      //     dicamoApi.requests.getTemplateList.method,
      //     dicamoApi.requests.getTemplateList.url,
      //     data
      //   )
      //   .then(res => {
      //     // console.log(res.data.data)
      //     this.templateList = res.data.data
      //     this.isLoading = false
      //     // this.lcdlist = res.data.data
      //   })
      //   .catch(error => {
      //     this.lcdlist = []
      //     console.debug(`Could not find any template. error: ${error}`)
      //     this.isLoading = false
      //   })

      const params = { store: this.$store.state.dataStore.selectedStore.code }
      const config = { params: params }
      params.company = this.$store.state.auth.user.company
      params.searchWord = ''
      params.page = 1
      params.linesPerPage = 100000000

      this.$utils
        .callAxios(
          dicamoApi.requests.getAssignTemplateList.method,
          dicamoApi.requests.getAssignTemplateList.url,
          config
        )
        .then(res => {
          if (res.data.responseCode === 200) {
            this.templateList = res.data.data
          } else {
            this.templateList = []
          }
        })
        .catch(error => {
          this.templateList = []
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`error: ${error}`)
        })
    },

    getProductList () {
      const config = {
        params: {
          store: this.$store.state.dataStore.selectedStore.code,
          company: this.$store.state.auth.user.company
        }
      }
      this.$utils
        .callAxios(
          codes.requests.getArticleDataKeyList.method,
          codes.requests.getArticleDataKeyList.url,
          config
        )
        .then(res => {
          // this.saveRequestConfig(res.config)
          // this.products = this.convertProductsDate(res.data.articleList)
          // this.totalProducts = this.products.length
          // this.pageInfoText = commons.getPageInfoText(res.headers)
          // this.totalPages = Number(res.headers['x-totalpages'])
          // this.selected = []
          this.targetFieldItems = res.data.articleDataKeyList
        })
        .catch(error => {
          this.products = []
          this.totalProducts = this.products.length
          const headers = {}
          headers['x-totalelements'] = 0
          this.pageInfoText = commons.getPageInfoText(headers)
          this.totalPages = 0
          this.selected = []
          console.debug(`Could not find any products. error: ${error}`)
        })
    }
  }
}
</script>

<style scoped>
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
